import { graphql } from "gatsby"
import React, { FC } from "react"
import { Layout } from "src/components/Layout"
import Jumbotron from "src/components/Jumbotron"
import { Props } from "./types"

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
  },
}) => (
  <Layout title={texts.header}>
    <Jumbotron title={texts.header} subtitle="Coming soon" />
    <div style={{ height: "300px" }} />
  </Layout>
)

export const pageQuery = graphql`
  query($ymlPath: String!) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        header
      }
    }
  }
`

export default Template
