import React, { FC } from "react"
import * as styles from "./styles.module.scss"
import { Props } from "./types"

const Jumbotron: FC<Props> = ({
  children,
  className = "",
  richText = "",
  subtitle,
  title,
  topContent,
}) => {
  function createMarkup() {
    return { __html: richText }
  }
  return (
    <div className={`${styles.root} ${className}`}>
      {topContent}
      <h1 className={styles.title}>{title}</h1>
      {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
      {richText && (
        <div
          className={styles.richText}
          dangerouslySetInnerHTML={createMarkup()}
        />
      )}
      {children}
    </div>
  )
}

export default Jumbotron
